import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import 'twin.macro'
import Plans from './components/plans'
import AuthGate from "../../components/authGate"

const PlanPage = (props) => {
	const title = (props.location.state && props.location.state.title) ? props.location.state.title : 'Plans'
	return <Layout {...props}>
		<SEO title={title}/>
		<AuthGate {...props} entry="signup"><Plans title={title} paid={true} {...props}/></AuthGate>
	</Layout>
}

export default PlanPage