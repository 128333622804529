import react, { useContext, useEffect, useState } from "react"
import { navigate } from "gatsby"
import { useUser } from "../hooks/useUser"
import UiStoreContext from "../contexts/uiStoreContext"
import { User, CognitoUser } from "../classes/user"
import 'twin.macro'
const AuthGate = ( {children, location, entry='login' } ) => {

	if (typeof window === 'undefined') {
		return null
	}
	
	const uiStore = useContext(UiStoreContext)
	const { user, refreshAuthenticatedUser } = useUser()
	const [renderChildren, setRenderChildren] = useState(false)

	useEffect( () => {

		// wait for user to be determined: either Cognito or anonymous
		if (typeof user === 'undefined') {
			// console.log('authGate user undefined')
			return
		}
		
		if (uiStore.gate && uiStore.gate.postAuthLocation === location.pathname) {
			setRenderChildren(false)
			// console.log("authGate back")
			uiStore.setGate(null)
			uiStore.resetPlanFlow()
			navigate(-1)
			return
		}
	
		if (!(user instanceof CognitoUser)) {
			setRenderChildren(false)
			console.log('authgate user not CognitoUser', entry, location.pathname)
			// console.log(react["__SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED"].ReactDebugCurrentFrame.getCurrentStack())
			uiStore.setGate({
				preAuthLocation: `/${entry}`,
				postAuthLocation: location.pathname
			})
		}
		else if (!(user.email_verified)) {
			setRenderChildren(false)
			uiStore.setGate({
				preAuthLocation: `/verify`,
				preAuthParams: {
					state: {
						email: user.email
					}
				},
				postAuthLocation: location.pathname
			})
		}
		else {
			setRenderChildren(true)
		}
	
	}, [user])

	return renderChildren ? children : null

}

export default AuthGate